<template>
  <div id="app">
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  created(){
    window.EventBus.$on('toast-success', this.makeToast)
  },
  methods:{
    makeToast(title, contents){
        this.$bvToast.toast(contents, {
            title: title,
            toaster: 'b-toaster-bottom-center',
            autoHideDelay: 2000,
            solid: true,
            appendToast: true,
            variant: 'default'
        })
    }
  },
}

</script>