import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import '@/plugins/axios'
import '@/plugins/bootstrap-vue'
import '@/plugins/start'
import App from './App.vue'
import router from './router'
import store from '@/store'

import cssVars from 'css-vars-ponyfill'
import i18n from '@/i18n'

import SubComponent from '@/components/main_layout/sub_component/SubComponent'
import BackToTop from '@/components/utility/BackToTop'
import NamePlate from '@/components/utility/NamePlate'
import CampaignPlate from '@/components/utility/CampaignPlate'
import SearchableInput from '@/components/utility/SearchableInput'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


window.EventBus = new Vue()

Vue.config.productionTip = false
Vue.component('fa', FontAwesomeIcon)
Vue.component('sub-component',SubComponent)
Vue.component('back-to-top',BackToTop)
Vue.component('name-plate',NamePlate)
Vue.component('campaign-plate', CampaignPlate)
Vue.component('searchable-input', SearchableInput)


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

Vue.directive('scroll', {
    inserted: function (el, binding) {
      let f = function (evt) {
        if (binding.value(evt, el)) {
          window.removeEventListener('scroll', f)
        }
      }
      window.addEventListener('scroll', f)
    }
})

window.Vue = Vue

cssVars({
  rootElement: document,
  shadowDOM: false,

  include: 'link[rel=stylesheet], style',
  exclude: '',
  variables: {},

  onlyLegacy: true,
  preserveStatic: true,
  preserveVars: false,
  silent: false,
  updateDOM: true,
  updateURLs: true,
  watch: false

})
