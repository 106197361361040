import Vue from 'vue'
import Router from 'vue-router'
import store from '../../store'
//import axios from 'axios'

import CsManager from '@/components/managing_operation/CsManager'
import HelpManager from '@/components/managing_operation/HelpManager'
import NoticeManager from '@/components/managing_operation/NoticeManager'

import IntroLanding from '@/components/provider_landing/scm/IntroLanding'
import SupplierLanding from '@/components/provider_landing/scm/SupplierLanding'
import ProviderMainLayout from '@/components/provider_landing/ProviderMainLayout'
import SupplierHeader from '@/components/provider_landing/scm/SupplierHeader'

import ProviderLandingWrapper from '@/components/provider_landing/ProviderLandingWrapper'

import TermsLayout from '@/components/provider_landing/service_policies/TermsLayout'
import TermsOfUse from '@/components/provider_landing/service_policies/TermsOfUse'
import PrivacyPolicy from '@/components/provider_landing/service_policies/PrivacyPolicy'

import ProviderAside from '@/components/provider_landing/ProviderAside'
import ProviderFooter from '@/components/provider_landing/ProviderFooter'
import Preface from '@/components/main_layout/Preface'

import UserMainLayout from '@/components/UserMainLayout'
import UserHeader from '@/components/UserHeader'
import UserFooter from '@/components/UserFooter'
import UserSubLayout from '@/components/UserSubLayout'

import TemplateList from '@/components/editing_template/TemplateList'
import EditingTemplate from '@/components/editing_template/EditingTemplate'

import ManagingCompany from '@/components/managing_portfolio/ManagingCompany'
import PortfolioList from '@/components/managing_portfolio/PortfolioList'
import EditingPortfolioAttr from '@/components/managing_portfolio/EditingPortfolioAttr'

import CampaignList from '@/components/managing_campaigns/CampaignList'
import EditingCampaignAttr from '@/components/managing_campaigns/EditingCampaignAttr'
import CampaignReport from '@/components/managing_campaigns/CampaignReport'
import CampaignProgress from '@/components/managing_campaigns/CampaignProgress'
import Answers from '@/components/managing_campaigns/Answers'
import ImprovementMonitoring from '@/components/managing_campaigns/ImprovementMonitoring'

import NotFound from '@/components/NotFound'
import Questions from '@/components/main_layout/Questions'
import Comments from '@/components/main_layout/comments/Comments'

import Login from '@/components/provider_landing/scm/Login'

import Register from '@/components/register_tierzero/Register'


const routes = [
  { path: '/', name: 'Login', component: Login},
  { path: '', component: ProviderLandingWrapper,
    children:[
      { path: '/main',
        name: 'Main',
        components: { provider_header: SupplierHeader, default: IntroLanding },
        meta: {requireAuth: true}
      },
    //   { path: '/provider_landing',
    //     name: 'ProviderLanding',
    //     components: { provider_aside: ProviderAside,provider_header: SupplierHeader, default: SupplierLanding, provider_footer: ProviderFooter },
    //     meta: {requireAuth: true}
    //   },
    ],
    props: true, 
    meta: {requireAuth:true}
  },
  { path: '/provider_main', component: ProviderMainLayout,
    children: [
      { path: '/provider_preface',
        name: 'ProviderPreface',
        components: { provider_header: SupplierHeader, provider_aside: ProviderAside, default: SupplierLanding, provider_right: '', provider_footer: ProviderFooter},
        meta: {requireAuth:true}
      },
      { path: '/questions/:question_code',
        name: 'Questions',
        components: { provider_header: SupplierHeader, provider_aside: ProviderAside, default: Questions, provider_right: '', provider_footer: ProviderFooter},
        props: true,
        meta: {requireAuth:true}
      },
    ],
    props: true,
    meta: {requireAuth:true}
  },
  {
    path: '/user_main', component: UserMainLayout,
    children: [
      { path: '/campaign_list',
        name: 'UserMainLayout',
        components: { user_header: UserHeader, default: CampaignList, user_footer: UserFooter }
      },
      {
        path: '/template_list',
        name: 'TemplateList',
        components: { user_header: UserHeader, default: TemplateList, user_footer: UserFooter }
      },
      {
        path: '/user_sub', 
        components: { user_header: UserHeader, default: UserSubLayout, user_footer: UserFooter },
        children: [
            {
                path: '/campaign_report',
                name: 'CampaignReport',
                components: { user_header: UserHeader, default: CampaignReport, user_footer: UserFooter }
            },
            {
                path: '/answers',
                name: 'Answers',
                components: { user_header: UserHeader, default: Answers, user_footer: UserFooter }
            },
            {
                path: '/campaign_progress',
                name: 'CampaignProgress',
                components: { user_header: UserHeader, default: CampaignProgress, user_footer: UserFooter }
            },
            {
                path: '/improvement_monitoring',
                name: 'ImprovementMonitoring',
                components: { user_header: UserHeader, default: ImprovementMonitoring, user_footer: UserFooter }
            },
            //기업 및 참여자 등록 component
            {
                path: '/managing_company',
                name: 'ManagingCompany',
                components: { user_header: UserHeader, default: ManagingCompany, user_footer: UserFooter},
                props: true
            },
            {
                path: '/portfolio_list',
                name: 'PortfolioList',
                components: { user_header: UserHeader, default: PortfolioList, user_footer: UserFooter },
                props: true
            },
            {
                path: '/editing_portfolio_attr',
                name: 'EditingPortfolioAttr',
                components: { user_header: UserHeader, default: EditingPortfolioAttr, user_footer: UserFooter },
                props: true
            },
            //운영관리 component
            {
                path: '/cs_manager',
                name: 'CsManager',
                components: { user_header: UserHeader, default: CsManager, user_footer: UserFooter }
            },
            {
                path: '/help_manager',
                name: 'HelpManager',
                components: { user_header: UserHeader, default: HelpManager, user_footer: UserFooter }
            },
            {
                path: '/notice_manager',
                name: 'NoticeManager',
                components: { user_header: UserHeader, default: NoticeManager, user_footer: UserFooter }
            },
        ],
        props: true,
      },
      {
        path: '/editing_template',
        name: 'EditingTemplate',
        components: { user_header: UserHeader, default: EditingTemplate, user_footer: UserFooter}
      },
      {
        path: '/editing_campaign_attr',
        name: 'EditingCampaignAttr',
        components: { user_header: UserHeader, default: EditingCampaignAttr, user_footer: UserFooter }
      },
    ],
    props: true,
    meta: {requireAuth:true}
  },
  { path: '/terms_layout',
    component: TermsLayout ,
    children: [
        { path: '/terms_of_use',
          name: 'TermsOfUse',
          components: { default: TermsOfUse}
        },
        { path: '/privacy_policy',
          name: 'PrivacyPolicy',
          components: { default: PrivacyPolicy }
        }
    ],
    props: true,
  },
  { path: '/reg', name: 'Register', component: Register},
  { path: '*', component: NotFound},
  
]

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes, 
    scrollBehavior(to, from, savedPosition){
      return { x:0, y:0 }
    }
  })

router.beforeEach((to, from, next) => {
    if(to.matched.some(record=> record.meta.requireAuth)) {
        if (store.getters.isLoggedIn && store.getters.isAuthenticated == 'success') {
            next()
            return
        }
        else if(store.getters.isLoggedIn && store.getters.isAuthenticated != 'success') {
            axios.defaults.headers.common['Authorization']= `Bearer ${store.getters.getAccessToken}`
            next()
            return
        }
        next({name: 'Login'})
    } 
    else {
        next()
    }
})




export default router
